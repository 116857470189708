/* Light theme styles */
.light-theme .home,
.light-theme .img-box,
.light-theme .map,
.light-theme .home-text,
.light-theme .home-title,
.light-theme .home-title span {
  background-color: #fdf8ee;
}

.light-theme .stats-box,
.light-theme .stats,
.light-theme .stats-text,
.light-theme .stats-icon,
.light-theme .community,
.light-theme .community-text,
.light-theme .community-image {
  background-color: #2f4858;
  color: white;
}

.light-theme .email-text {
  color: #212121;
}

/* Dark theme styles */
.dark-theme .home,
.dark-theme .map,
.dark-theme .home-text,
.dark-theme .home-title,
.dark-theme .home-title span {
  background-color: #212121;
}

.dark-theme .stats-box,
.dark-theme .stats,
.dark-theme .stats-text,
.dark-theme .stats-icon,
.dark-theme .community,
.dark-theme .community-text,
.dark-theme .community-image {
  background-color: #79858d;
}

.dark-theme .email-text {
  color: #c3c3c3;
}

/* buttons */
.learn-button {
  background: var(
    --Yellow-Orange-Gradient,
    linear-gradient(180deg, #fcb813 0%, #f48120 100%)
  );
  color: #212121;
  padding: 10px 25px;
  text-transform: uppercase;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.map {
  width: 650px;
}

/*Stats box*/
.stats {
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  justify-content: space-between;
  bottom: -12%;
}

.home-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.stats-box,
.stats-icon {
  display: flex;
  background-color: #79858d;
  align-items: center;
}

.stats-box {
  padding: 0px 20px;
  border-left: 0;
}

.stats-text {
  padding-top: 10px;
  margin-left: 10px;
  background-color: #79858d;
}

/* Function Section */
.function {
  padding: 0;
}

.functionality-row h3 {
  margin: 20px 0px;
}

.input-box {
  border-radius: 10px;
  border: 0.5px solid #959292;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  color: #212121;
  padding-left: 10px;
}

/* Banner */
.community-image {
  width: 180px;
}

/* Laptop view */
@media screen and (min-width: 1280px) and (max-width: 1536px) {
  .map {
    width: 570px;
  }

  .stats {
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    background-color: #f5f5f5;
    z-index: 100;
  }

  .stats-box,
  .stats-icon {
    display: flex;
    background-color: #79858d;
    align-items: center;
  }

  .stats-box {
    padding: 0px 50px;
  }

  .stats-text {
    padding-top: 10px;
    margin-left: 8px;
    background-color: #79858d;
  }

  .function {
    padding: 0 40px;
  }
}

/* Tablet view */
@media screen and (max-width: 1279px) {
  .map {
    width: 445px;
  }

  .learn-button {
    font-size: 15px;
  }
}
@media screen and (max-width: 1024px) {
  .functionality-row h3 {
    font-size: 20px;
  }

  .functionality-body {
    font-size: 12px;
  }

  .map {
    width: 375px;
  }

  .stats-box {
    padding: 0px 10px;
  }

  .stats-text {
    padding-top: 10px;
    margin-left: 10px;
  }
  .stats {
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    background-color: #f5f5f5;
    z-index: 100;
  }

  .stats-icon {
    width: 60px;
  }

  .learn-button {
    padding: 10px 20px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
  }
  .functionality-image {
    width: 60px;
  }
  .community-image {
    width: 160px;
  }
}

@media screen and (max-width: 840px) {
  .map {
    width: 380px;
  }
}

/*Mobile View*/
@media screen and (min-width: 375px) and (max-width: 767px) {
  .functionality-row h3 {
    font-size: 20px;
  }

  .functionality-body {
    font-size: 15px;
  }

  .learn-button {
    padding: 5px 15px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
  }

  .functionality-image {
    width: 60px;
    margin-right: 20px;
  }

  .community-image {
    width: 130px;
  }

  .functionality-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}
