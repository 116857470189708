.light-theme .governance-banner,
.light-theme .governance-box,
.light-theme .governance-title,
.light-theme .governance-body {
  background-color: #2f4858;
  color: #f1f1f1;
}

.dark-theme .governance-banner,
.dark-theme .governance-box,
.dark-theme .governance-title,
.dark-theme .governance-body {
  background-color: #79858d;
  color: #f1f1f1;
}
.dark-theme .tsc-title,
.tsc-span {
  color: #f48120;
}

.light-theme .tsc-span {
  color: #2539ed;
  text-decoration: underline;
}
