.navbar li {
  font-size: larger;
}

/* .dark-theme summary:hover,
.dark-theme a:hover {
  text-decoration: none;
  color: white;
  background-color: #212121;
} */

.light-theme summary,
.light-theme a {
  color: #212121;
  background-color: white;
  transition: color 0.3s all;
}

.light-theme summary:hover,
.light-theme .navbar a:hover {
  color: white;
  background-color: #2f4858;
}

.light-theme .lf-back,
.light-theme .lf-banner {
  background-color: #212121;
}

.lf-banner {
  width: 300px;
}
