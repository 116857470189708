@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*gotham font looks terrible*/
/* body {
  margin: 0;
  font-family: 'Gotham';
  src: url('../src/fonts/GothamMedium.ttf') format('woff2'),
    url('../src/fonts/GothamLight.ttf') format('woff');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* To change one themes are changed */
.light-theme * {
  background-color: white;
  color: black;
}

.dark-theme * {
  background-color: #212121;
  color: white;
}

.dark-theme p {
  color: #c3c3c3;
}

h1 {
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 55px */
  letter-spacing: 1.5px;
}

h2 {
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
}

h3 {
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

h4 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

.banner {
  display: flex;
  align-items: center;
  height: 100%;
  background-color: #79858d;
  justify-content: space-between;
}

.banner-text {
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  padding-left: 190px;
  background-color: #79858d;
}

.banner-image {
  display: flex;
  align-items: flex-end;
  width: 550px;
  background-color: #79858d;
}

.light-theme .banner-text {
  background-color: #2f4858;
  color: #fff !important;
}

.light-theme .banner {
  background-color: #2f4858;
}

.light-theme .banner-image {
  background-color: #2f4858;
}

.dark-theme ul li {
  color: #c3c3c3;
}

.intro {
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.footer {
  padding: 5rem 3rem;
}

.footer-text {
  display: flex;
  flex-direction: column;
  width: 480px;
}

.resources li {
  margin-top: 12px;
}

.links li {
  margin-top: 12px;
}

.dark-theme .cards-box {
  border: 0.5px whitesmoke solid;
}

.light-theme .cards-box {
  border: 0.2px #2f4858 ridge;
}

.light-theme .cards-box:hover {
  filter: drop-shadow(0px 2px 20px rgba(47, 72, 88, 0.4));
  transition: 0.3s;
}

.dark-theme .cards-box:hover {
  filter: drop-shadow(0px 2px 20px rgba(245, 131, 32, 0.4));
  transition: 0.3s;
}

@media screen and (min-width: 2000px) {
  .banner-text {
    padding-left: 24%;
  }
}

@media screen and (min-width: 3000px) {
  .banner-text {
    padding-left: 30%;
  }
}

/*laptop*/
@media screen and (min-width: 1280px) and (max-width: 1536px) {
  .banner-text {
    padding-left: 70px;
    font-size: 35px;
  }
  .banner-image {
    width: 310px;
    height: 230px;
  }
}

/*tablet*/
@media screen and (min-width: 1025px) and (max-width: 1279px) {
  .banner-text {
    padding-left: 70px;
    font-size: 25px;
  }
  .banner-image {
    width: 280px;
    height: 200px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .banner-text {
    padding-left: 50px;
    font-size: 25px;
  }

  .banner-image {
    width: 250px;
    height: 180px;
  }
}

/*mobile*/
@media screen and (min-width: 375px) and (max-width: 768px) {
  .banner {
    display: flex;
    align-items: center;
    background-color: #79858d;
    justify-content: space-between;
  }

  .banner-text {
    padding-left: 40px;
    font-size: 20px;
  }

  .banner-image {
    width: 170px;
    height: 150px;
  }
  .footer-text {
    width: 240px;
  }
}

@media screen and (max-width: 767px) {
  .content-image {
    display: none;
  }
}
