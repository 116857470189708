.dark-theme .title {
  color: rgba(252, 184, 19, 100%) !important;
}

.light-theme .title {
  color: black !important;
}

.platform-text span {
  color: #fdb913 !important;
  display: inline-block;
}

.row {
  display: flex;
  flex-direction: row;
}

.column-1,
.column-5 {
  display: flex;
  flex-direction: column;
  text-align: justify;
  width: 65%;
  line-height: 150%;
}

.column-2,
.column-6 {
  display: flex;
  flex-direction: column;
  width: 35%;
  align-items: center;
  justify-content: center;
}

.platform-text {
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.dark-theme .architecture-heading {
  color: rgba(252, 184, 19, 100%);
}

.light-theme .architecture-heading {
  color: black;
}

.shape_1 {
  width: 380px;
}

.shape_2 {
  width: 320px;
  padding-bottom: 15px;
}

.column-3 {
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: flex-start;
  justify-content: center;
}

.column-4 {
  display: flex;
  flex-direction: column;
  text-align: justify;
  width: 75%;
  line-height: 150%;
}

.modular-protocol ul {
  list-style-type: inherit;
  align-items: flex-start;
  padding-left: 20px;
}

.dark-theme .modular-protocol li {
  color: #c3c3c3;
}

/*tablet*/
@media screen and (max-width: 1279px) {
  .shape_1 {
    width: 280px;
  }

  .shape_2 {
    height: 350px;
    padding-bottom: 0;
  }

  .shape_3 {
    width: 240px;
  }
}

@media screen and (max-width: 1024px) {
  .column-1,
  .column-2,
  .column-3,
  .column-4,
  .column-5,
  .column-6 {
    width: 100%;
  }
  .shape_1 {
    width: 300px;
    height: 350px;
  }

  .shape_2 {
    height: 450px;
  }

  .shape_3 {
    width: 250px;
    height: 350px;
  }
}

/*mobile*/
@media screen and (min-width: 375px) and (max-width: 767px) {
  .column-1,
  .column-2,
  .column-3,
  .column-4,
  .column-5,
  .column-6 {
    width: 100%;
  }
  .shape_1,
  .shape_2,
  .shape_3 {
    display: none;
  }
}
