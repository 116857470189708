s.light-theme .started-banner,
.light-theme .started-box,
.light-theme .started-title,
.light-theme .started-body,
.light-theme .started-span,
.light-theme .buttons {
  background-color: #2f4858;
  color: #f1f1f1;
}

.dark-theme .started-banner,
.dark-theme .started-box,
.dark-theme .started-title,
.dark-theme .started-body,
.dark-theme .started-span,
.dark-theme .buttons {
  background-color: #79858d;
  color: #f1f1f1;
}

.dark-theme .reach-out {
  color: #c3c3c3;
}

.download-button {
  background: var(
    --Yellow-Orange-Gradient,
    linear-gradient(180deg, #fcb813 0%, #f48120 100%)
  );
  color: #212121;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
}

.light-theme .name,
.light-theme .company-name {
  color: #ff7426;
}

.dark-theme .name,
.dark-theme .company-name {
  color: #ff7426;
}

@media screen and (min-width: 768px) {
  .resources-cards {
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  .resources-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
