/* Light theme styles */
.light-theme .user,
.light-theme .user-text,
.light-theme .odl-logo,
.light-theme .user-title,
.light-theme .user-title span,
.light-theme .testimonials,
.light-theme .testimonials-title,
.light-theme .odl-image {
  background-color: #fff8e6;
}

.dark-theme .user-stories,
.dark-theme .odl-logo,
.dark-theme .user-text,
.dark-theme .user-title,
.dark-theme .user-title span,
.dark-theme .testimonials,
.dark-theme .testimonials-title,
.dark-theme .blue-box,
.dark-theme .odl-image {
  background: #79858d;
  color: #f5f5ff;
}

.dark-theme .user-title,
.dark-theme .user-title span {
  color: #212121;
}
.dark-theme .testimonial-company {
  color: #fdc221;
  font-weight: 600;
}

.odl-logo {
  width: 450px;
}

.user-stories {
  clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 0% 95%);
}

.blue-box {
  background-color: #f5f9ff;
  border-radius: 150px 0px 0px 0px;
  width: 75%; /*both boxes are sticking because of this.*/
  height: 600px;
  position: relative; /*both boxes are sticking because of this.*/
}

.success-box {
  border-radius: 100px 0px 0px 0px;
  background: #fff5db;
  backdrop-filter: blur(2px);
  width: 22%; /*both boxes are sticking because of this.*/
  height: 358px;
  flex-shrink: 0;
  left: 150px; /*both boxes are sticking because of this.*/
  transform: translateY(50%);
  right: 100%; /*both boxes are sticking because of this.*/
}

.dark-theme .success-box {
  background-color: #676e76;
}
.light-theme .success-text {
  background: #fff5db;
}

.dark-theme .success-text {
  background: #676e76;
  color: #fdc221;
}

.dark-theme .read-more {
  color: #fdc221;
}

.light-theme .read-more {
  color: #2539ed;
}

.asd-card {
  left: 40%;
  top: -10px;
}

.nro-card {
  left: 40%;
  bottom: -5px;
}

.nfv-card {
  left: 65%;
  top: -70px;
}

.visibility-card {
  left: 65%;
  bottom: 80px;
}

.card-image {
  width: 100px;
}

@media screen and (min-width: 2000px) {
  .odl-logo {
    width: 500px;
  }

  .persons-image {
    width: 200px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1536px) {
  .odl-logo {
    width: 400px;
  }

  .blue-box {
    background-color: #f5f9ff;
    border-radius: 150px 0px 0px 0px;
    width: 75%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .success-box {
    border-radius: 100px 0px 0px 0px;
    background: #fff5db;
    backdrop-filter: blur(2px);
    width: 22%;
    height: 200px;
    flex-shrink: 0;
    align-items: center;
    display: flex;
    right: 100%;
    top: 20px;
  }

  .asd-card {
    left: 40%;
    top: -130px;
  }

  .nro-card {
    left: 40%;
    bottom: -80px;
  }

  .nfv-card {
    left: 70%;
    top: -180px;
  }

  .visibility-card {
    left: 70%;
    bottom: -30px;
  }

  .card-image {
    width: 80px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .odl-logo {
    width: 400px;
  }
  .success-box {
    height: 240px;
    display: flex;
    align-items: center;
    top: 60px;
  }

  .asd-card {
    left: 40%;
    top: -10%;
  }

  .nro-card {
    left: 40%;
    bottom: -5%;
  }

  .nfv-card {
    left: 70%;
    top: -18%;
  }

  .visibility-card {
    left: 70%;
    bottom: 2%;
  }
  .card-image {
    width: 80px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .odl-logo {
    width: 350px;
  }

  .user-stories {
    clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 0% 94%);
  }
}

/*Mobile View*/
@media screen and (min-width: 375px) and (max-width: 767px) {
  .odl-image {
    display: none;
  }
  .user-stories {
    clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 0% 90%);
  }

  .testimonials-boxes {
    display: flex;
    flex-direction: column;
  }

  .quote-image {
    width: 30px;
  }
}
