.dark-theme hr {
  border-color: #616161;
  height: 1px;
}

.light-theme hr {
  height: 0.5px;
  color: #212121;
  background-color: #212121;
}

.footer ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: block;
}

.footer-box {
  background-color: #212121;
}

.footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2rem 0rem;
}

.footer-text {
  width: 480px;
  text-align: justify;
  letter-spacing: -0.7px;
}

.footer .heading {
  color: orange;
  font-size: 18px;
  font-weight: 600;
}

.resources {
  flex-basis: 5%;
}

.links {
  flex-basis: 8%;
}

.resources li,
.links li {
  margin-top: 15px;
}

.dark-theme .copyright p {
  text-align: center;
  color: orange;
}

.light-theme .copyright p {
  text-align: center;
  color: black;
}

/* Tablet view */
@media screen and (max-width: 1024px) {
  .footer-text {
    width: 350px;
    text-align: justify;
    letter-spacing: -0.7px;
  }
}

/*Mobile View*/
@media screen and (min-width: 375px) and (max-width: 767px) {
  .footer-text {
    width: 100%;
  }

  .resources,
  .links,
  .footer-content {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}
